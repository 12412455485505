import React from 'react';

import { Helmet } from 'react-helmet';

const HelmetLayout = ({ children }) => (
	<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Karnott - Le compteur connecté qui simplifie le suivi de vos interventions</title>
      <meta name="description" content="Oubliez vos carnets de travaux, découvrez Karnott, le compteur qui simplifie la gestion de vos activités" />
			<link rel="canonical" href="https://karnott.thomasgrivet.fr" />
		</Helmet>
		{children}
	</>
)

export default HelmetLayout;
