import React from 'react';
import { Link } from 'gatsby';

import BackgroundImage from '../../../assets/images/pages/become-distributor/background.png';
import BackgroundImageLowDef from '../../../assets/images/pages/become-distributor/background_low_def.png';

import BecomeDistributorForm from './become_distributor_form/become_distributor_form';
import AllRightsReserved from '../../small_views/all_rights_reserved/all_rights_reserved';
import OptimisedImage from '../../small_views/optimised_image/optimised_image';

import KarnottLogo from '../../../assets/images/views/top_bar/logo-karnott.svg';

import classes from './become_distributor_content.module.css';

const ContactContent = () => (
	<div className={classes.container}>
		<div className={classes.backgroundContainer}>
			<OptimisedImage
				alt="Devenez distributeur Karnott"
				className={classes.background}
				normal={BackgroundImage}
				lowDef={BackgroundImageLowDef}
			/>
		</div>
		<div className={classes.content}>
			<Link to="/" className={classes.logo}>
				<KarnottLogo />
			</Link>
			<h1 className={classes.title}>
				{"Prenez une longueur d'avance"}
			</h1>
			<BecomeDistributorForm />
			<AllRightsReserved className={classes.allRightsReserved} />
		</div>
	</div>
);

export default ContactContent;
