import React, { forwardRef, useCallback } from 'react'

import cn from 'classnames'
import { animated, config, useSpring } from 'react-spring'

import palette from '../../../utils/color_palette'

import CheckIcon from '../../../assets/images/views/checkbox/check.svg'

import classes from './checkbox.module.css'

const DEFAULT_BRIGHT_LAYER_SPRING_PROPS = {
	opacity: 0,
	config: config.stiff
}

const Checkbox = forwardRef(
	(
		{
			component: Component = 'div',
			checked,
			disabled,
			color,
			className,
			inputClassName,
			containerProps,
			onChange,
			onFocus,
			onBlur,
			onMouseEnter,
			onMouseLeave,
			variant = 'raised',
			...other
		},
		ref
	) => {
		const [brightLayerSpringProps, setBrightLayerSpringProps] = useSpring(
			() => DEFAULT_BRIGHT_LAYER_SPRING_PROPS
		)

		const handleChange = useCallback(
			(...parameters) => {
				if (disabled) {
					return
				}
				if (typeof onChange === 'function') {
					onChange(...parameters)
				}
			},
			[disabled, onChange]
		)
		const showBrightLayer = useCallback(() =>
			setBrightLayerSpringProps(() => ({
				opacity: 0.3
			})))

		const dismissBrightLayer = useCallback(() =>
			setBrightLayerSpringProps(() => DEFAULT_BRIGHT_LAYER_SPRING_PROPS))

		const handleMouseEnter = useCallback(
			(...parameters) => {
				if (typeof onMouseEnter === 'function') {
					onMouseEnter(...parameters)
				}
				showBrightLayer()
			},
			[onMouseEnter]
		)

		const handleMouseLeave = useCallback(
			(...parameters) => {
				if (typeof onMouseLeave === 'function') {
					onMouseLeave(...parameters)
				}
				dismissBrightLayer()
			},
			[onMouseLeave]
		)

		const handleFocus = useCallback(
			(...parameters) => {
				if (typeof onFocus === 'function') {
					onFocus(...parameters)
				}
				showBrightLayer()
			},
			[onFocus]
		)

		const handleBlur = useCallback(
			(...parameters) => {
				if (typeof onBlur === 'function') {
					onBlur(...parameters)
				}
				dismissBrightLayer()
			},
			[onBlur]
		)
		return (
			<Component
				className={cn(
					classes.container,
					checked && classes.filled,
					disabled && classes.disabled,
					color && palette[color] && classes.withColor,
					classes[variant],
					className
				)}
				style={{
					...(color
            && palette[color] && {
						color: palette[color][500]
					}),
					...(containerProps && containerProps.style)
				}}
				{...{ ref }}
				{...containerProps}
			>
				<CheckboxIconComponent {...{ checked }} />
				<animated.div
					className={classes.brightLayer}
					style={{
						opacity: brightLayerSpringProps.opacity
					}}
				/>
				<input
					className={cn(classes.input, inputClassName)}
					type="checkbox"
					onChange={handleChange}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onFocus={handleFocus}
					onBlur={handleBlur}
					{...other}
				/>
			</Component>
		)
	}
);

const CheckboxIconComponent = ({ checked }) => {
	const { opacity, scale } = useSpring({
		opacity: checked ? 1 : 0,
		scale: checked ? 1 : 0.8,
		config: config.stiff
	})
	return (
		<animated.div
			className={classes.checkIconContainer}
			style={{
				opacity,
				transform: scale.interpolate(
					value => `scale3d(${value}, ${value}, ${value})`
				)
			}}
		>
			<CheckIcon className={classes.checkIcon} />
		</animated.div>
	)
}

const RaisedCheckbox = (props) => {
	// Springs?
	return (
		<Checkbox
			variant="raised"
			{...props}
		/>
	);
}

const WithVariantCheckbox = ({ variant, ...other }) => {
	if (variant === 'raised') {
		return <RaisedCheckbox {...{ variant }} {...other} />
	}
	return <Checkbox {...{ variant }} {...other} />
}

export default WithVariantCheckbox;
