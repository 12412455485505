import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import BecomeDistributorContent from '../components/become_distributor/become_distributor_content/become_distributor_content';

const Contact = () => (
	<HelmetLayout>
		<BecomeDistributorContent />
	</HelmetLayout>
);

export default Contact;
