import React, { useEffect, memo } from 'react';

import ProgressiveImage from 'react-progressive-image-loading';

const OptimisedImage = memo(({
	imageComponent: ImageComponent = 'img',
	normal,
	lowDef,
	onReady,
	additionnalFilter,
	style,
	...other
}) => {
	useEffect(() => {
		if (onReady) {
			onReady();
		}
	}, [onReady]);
	if (typeof Image === 'undefined') {
		return (
			<img
				alt="Default alt"
				src={lowDef}
				style={{
					filter: `blur(10px) ${additionnalFilter}`
				}}
				{...other}
			/>
		);
	}
	return (
		<ProgressiveImage
			transitionTime={500}
			preview={lowDef || normal}
			src={normal || lowDef}
			render={(src, filteredStyle) => (
				<ImageComponent
					alt="Default alt"
					style={{
						...style,
						...filteredStyle,
						...additionnalFilter && {
							filter: `${filteredStyle.filter} ${additionnalFilter}`
						}
					}}
					{...{ src }}
					{...other}
				/>
			)}
		/>
	)
});

export default OptimisedImage;
