import React from 'react';

import cn from 'classnames';

import HeartIcon from '../../../assets/images/views/all_rights_reserved/heart.svg';

import classes from './all_rights_reserved.module.css';

const AllRightsReserved = ({ className }) => {
	return (
		<div className={cn(classes.container, className)}>
			<span className={classes.allRightsReserved}>
				{'© 2016 - 2019 Karnott - Tous droits réservés - Made with'}
				<span className={classes.iconContainer}>
					<HeartIcon />
				</span>
			</span>
		</div>
	);
};

export default AllRightsReserved;
