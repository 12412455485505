const primary = {
	50: '#EFF8E6',
	100: '#D6EDC0',
	200: '#BBE196',
	300: '#9FD46B',
	400: '#8BCB4C',
	500: '#8cc047',
	600: '#6EBC27',
	700: '#63B421',
	800: '#59AC1B',
	900: '#469F10'
};

const emerald = {
	50: '#E5F1F0',
	100: '#BDDDDA',
	200: '#91C6C2',
	300: '#238c84',
	400: '#449D96',
	500: '#238c84',
	600: '#1F847C',
	700: '#1A7971',
	800: '#156F67',
	900: '#0C5C54',
	rgb: {
		500: [35, 140, 132]
	}
}

const secondary = {
	300: '#F9B362',
	400: '#F8A340',
	500: '#F7931E',
	600: '#F68B1A',
	700: '#F58016',
	800: '#F37612',
	900: '#F1640A',
	rgb: {
		500: [247, 147, 30]
	}
};

const error = {
	300: '#DB4D4D',
	400: '#D42626',
	500: '#cc0000'
}

const success = {
	300: '#81c784',
	400: '#66bb6a',
	500: '#4caf50',
	600: '#43a047',
	700: '#388e3c'
}

const warning = {
	300: '#ffd54f',
	400: '#ffca28',
	500: '#ffc107',
	600: '#ffb300',
	700: '#ffa000',
	800: '#ff8f00',
	900: '#f57f17'
}

const primaryRGB = {
	300: [227, 117, 111],
	400: [167, 93, 149],
	500: [118, 194, 44]
};

const secondaryRGB = {
	300: [184, 121, 168],
	400: [45, 133, 156],
	500: [8, 112, 138]
}

const light = {
	500: '#fff',
	rgb: {
		500: [255, 255, 255]
	}
}

module.exports = ({
	primary,
	emerald,
	secondary,
	error,
	success,
	warning,
	primaryRGB,
	secondaryRGB,
	light
});
