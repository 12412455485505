import React, { useCallback, useRef, useState } from 'react';

import formData from './form_data';

import TextField from '../../../design_system/text_field/text_field';
import TextFieldLabel from '../../../design_system/text_field/text_field_label/text_field_label';
import Checkbox from '../../../design_system/checkbox/checkbox';
import Button from '../../../design_system/button/button';

import classes from './become_distributor_form.module.css';

const getDefaultFieldsState = () => Object.keys(formData).reduce((state, id) => {
	const newState = state;
	newState[id] = {
		value: '',
		error: null
	}
	return newState;
}, {});

const BecomeDistributorForm = () => {
	const [fields, setFields] = useState(getDefaultFieldsState());
	const [, updateState] = useState();
	const [acceptanceChecked, setAcceptanceChecked] = useState(false);
	const handleChange = inputId => (event) => {
		if (!inputId || event.target.value === undefined) {
			return;
		}
		const newFields = fields;
		newFields[inputId].value = event.target.value;
		setFields(newFields);
		updateState({});
	};
	const handleAcceptanceChange = useCallback((event) => {
		setAcceptanceChecked(event.target.checked);
	});
	return (
		<div className={classes.container}>
			<div className={classes.text}>
				<div className={classes.title}>
					{'Lorem ipsum dolor sit amet, conse tetur adipiscing elit. Aenean leo felis, tincidunt da.'}
				</div>
				<div className={classes.description}>
					{'Sed eu ante sed mauris rhoncus blandit. Sed id sem vel elit consec tetur vestibulum. Mauris at mauris eu justo mollis scelerisque. Nam tempus bibendum vehicula. Pellentesque mollis velit in pulvinar rutrum. Quisque turpis velit, vestibulum sit amet quam eu, ornare ornare arcu. Maecenas nec sodales sem, luctus euismod felis. '}
				</div>
			</div>
			<div className={classes.textFieldsContainer}>
				<div className={classes.fields}>
					{Object.entries(formData).map(([id, field]) => (
						<Field
							key={`contact_field_${id}`}
							onChange={handleChange(id)}
							{...field}
							{...fields[id]}
							/>
					))}
				</div>
				<div className={classes.acceptanceActionsButton}>
					<div className={classes.checkAcceptanceRow}>
						<Checkbox
							className={classes.checkbox}
							checked={acceptanceChecked}
							onChange={handleAcceptanceChange}
							variant="outlined"
							/>
						<div>
							{"J'accepte que Karnott traite mes données conformément à la politique de confidentialité."}
						</div>
					</div>
					<div className={classes.actions}>
						<Button
							className={classes.button}
							variant="contained"
							color="emerald"
						>
							{'Envoyer ma demande >'}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const Field = ({
	label, type, onChange, value
}) => {
	const textFieldReference = useRef();
	const handleLabelClick = useCallback(() => {
		if (textFieldReference.current) {
			textFieldReference.current.focus();
		}
	}, [textFieldReference.current]);
	return (
		<div className={classes.field}>
			<TextField
				inputRef={textFieldReference}
				className={classes.textField}
				inputClassName={classes.input}
				beforeChildren={(
					<TextFieldLabel
						className={classes.label}
						onClick={handleLabelClick}
					>
						{label}
					</TextFieldLabel>
				)}
				{...{ type, value, onChange }}
			/>
		</div>
	);
};

export default BecomeDistributorForm;
