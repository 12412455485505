import React, { useCallback } from 'react';

import cn from 'classnames';
import { animated, config, useSpring } from 'react-spring';

import palette from '../../../utils/color_palette';

import classes from './button.module.css';

const Button = ({
	className,
	disabled,
	color,
	variant,
	children,
	style,
	onClick,
	...other
}) => {
	const handleClick = useCallback((...args) => {
		if (disabled) {
			return;
		}
		if (typeof onClick === 'function') {
			onClick(...args);
		}
	}, [onClick, disabled]);
	return (
		<animated.button
			style={{
				...color && palette[color] && {
					color: palette[color][500]
				},
				...style
			}}
			className={
				cn(classes.container,
					variant === 'text' && classes.variantText,
					variant === 'contained' && classes.variantContained,
					variant === 'outlined' && classes.variantOutlined,
					variant === 'underlined' && classes.variantUnderlined,
					color && palette[color] && classes.withColor,
					disabled && classes.disabled,
					className)}
			role="button"
			onClick={handleClick}
			{...other}
		>
			<span>
				{children}
			</span>
		</animated.button>
	);
}

const DEFAULT_BOUNCE_SPRING_PROPS = Object.freeze({
	translation: 0
});

const WithBounceButton = ({
	style,
	onMouseEnter,
	onMouseLeave,
	onFocus,
	onBlur,
	...props
}) => {
	const { disabled } = props;

	const [{ translation }, setSpringProps] = useSpring(() => DEFAULT_BOUNCE_SPRING_PROPS);

	const translateButtonUp = useCallback(() => {
		if (disabled) {
			return;
		}
		setSpringProps(() => ({
			translation: -3,
			config: config.stiff
		}));
	}, [disabled]);

	const translateButtonDefault = useCallback(() => {
		if (disabled) {
			return;
		}
		setSpringProps(() => DEFAULT_BOUNCE_SPRING_PROPS);
	}, [disabled]);

	const handleMouseEnter = useCallback((...parameters) => {
		if (typeof onMouseEnter === 'function') {
			onMouseEnter(...parameters)
		}
		translateButtonUp();
	}, [onMouseEnter]);

	const handleMouseLeave = useCallback((...parameters) => {
		if (typeof onMouseLeave === 'function') {
			onMouseLeave(...parameters);
		}
		translateButtonDefault();
	});

	const handleFocus = useCallback((...parameters) => {
		if (typeof onFocus === 'function') {
			onFocus(...parameters);
		}
		translateButtonUp();
	});

	const handleBlur = useCallback((...parameters) => {
		if (typeof onBlur === 'function') {
			onBlur(...parameters);
		}
		translateButtonUp();
	});

	return (
		<Button
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleFocus}
			onBlur={handleBlur}
			style={{
				...style,
				transform: translation.interpolate(value => `translate3d(0, ${value}px, 0)`)
			}}
			{...props}
		/>
	);
}

const BounceHandlerButton = ({ bounce = true, ...props }) => {
	if (bounce) {
		return <WithBounceButton {...props} />
	}
	return <Button {...props} />
}

const DEFAULT_UNDERLINE_SPRING_PROPS = ({
	translation: 100
});

const UnderlinedButton = ({
	children,
	underlineClassName,
	style,
	onMouseEnter,
	onMouseLeave,
	onFocus,
	onBlur,
	...other
}) => {
	const { disabled } = other;
	const [underlineSpringProps, setUnderlineSpringProps] = useSpring(() => DEFAULT_UNDERLINE_SPRING_PROPS);

	const translateUnderline = useCallback(() => {
		if (disabled) {
			return;
		}
		setUnderlineSpringProps(() => ({
			translation: 0
		}));
	}, [disabled]);

	const dismissUnderline = useCallback(() => {
		if (disabled) {
			return;
		}
		setUnderlineSpringProps(() => DEFAULT_UNDERLINE_SPRING_PROPS);
	}, [disabled]);

	const handleMouseEnter = useCallback((...parameters) => {
		if (typeof onMouseEnter === 'function') {
			onMouseEnter(...parameters)
		}
		translateUnderline();
	}, [onMouseEnter]);

	const handleMouseLeave = useCallback((...parameters) => {
		if (typeof onMouseLeave === 'function') {
			onMouseLeave(...parameters);
		}
		dismissUnderline();
	});

	const handleFocus = useCallback((...parameters) => {
		if (typeof onFocus === 'function') {
			onFocus(...parameters);
		}
		translateUnderline();
	});

	const handleBlur = useCallback((...parameters) => {
		if (typeof onBlur === 'function') {
			onBlur(...parameters);
		}
		dismissUnderline();
	});
	return (
		<BounceHandlerButton
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleFocus}
			onBlur={handleBlur}
			{...other}
		>
			{children}
			<div className={classes.underlineContainer}>
				<animated.div
					className={cn(classes.underline, underlineClassName)}
					style={{
						...style,
						transform: underlineSpringProps.translation.interpolate(value => `translate3d(${value}%, 0, 0)`)
					}}
				/>
			</div>
		</BounceHandlerButton>
	);
}

const VariantHandlerButton = ({ variant = 'text', ...props }) => {
	if (variant === 'underlined') {
		return <UnderlinedButton {...{ variant }} {...props} />
	}
	return <BounceHandlerButton {...{ variant }} {...props} />
}

export default VariantHandlerButton;
