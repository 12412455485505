import React from 'react';

import cn from 'classnames';

import palette from '../../../../utils/color_palette';

import classes from './text_field_label.module.css';

const TextFieldLabel = ({
	color, style, className, children, ...other
}) => (
	<div
		className={cn(classes.container, color && palette[color] && classes.withColor, className)}
		style={{
			...color && palette[color] && {
				color: palette[color][500]
			},
			...style
		}}
		{...other}
	>
		{children}
	</div>
);

export default TextFieldLabel;
