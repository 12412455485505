import React, { cloneElement, forwardRef } from 'react';

import cn from 'classnames';

import palette from '../../../utils/color_palette';

import classes from './text_field.module.css';

const TextField = forwardRef(({
	className,
	color,
	style,
	inputClassName,
	multiline,
	variant = 'flat',
	beforeChildren,
	children,
	inputRef,
	...other
}, ref) => {
	const InputComponent = multiline ? 'textarea' : 'input';
	return (
		<div
			{...{ ref }}
			className={
				cn(classes.container,
					classes[variant],
					color && palette[color] && classes.withColor,
					multiline && classes.multilineContainer,
					className)
			}
			style={{
				...color && palette[color] && {
					color: palette[color][500]
				},
				...style
			}}
		>
			{beforeChildren && React.Children.map(beforeChildren, child => cloneElement(child, { color }))}
			<InputComponent
				ref={inputRef}
				className={cn(classes.input, multiline && classes.multiline, inputClassName)}
				{...other}
			/>
			{children && React.Children.map(children, child => cloneElement(child, { color }))}
		</div>
	)
});

export default TextField;
